import './Loading.css';

export default function Loading() {
  return (
    <div className='loading'>
      <div className='lds-dual-ring'>
      
      </div>
    </div>
  )
}
